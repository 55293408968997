.dashboard {
    padding: 20px;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .logout-button {
    background-color: #ff4b5c;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .add-user-form, .edit-user-form {
    margin-bottom: 20px;
  }
  
  .add-user-form input, .edit-user-form input {
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-button, .save-button, .cancel-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .cancel-button {
    background-color: #ff4b5c;
  }
  
  .user-list {
    margin-top: 20px;
  }
  
  .user-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .user-item span {
    flex-grow: 1;
  }
  
  .edit-button, .delete-button, .password-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
  }
  
  .delete-button {
    background-color: #ff4b5c;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
  }
  