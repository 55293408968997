/* src/components/Calendar.css */

.p-4 {
    padding: 1rem;
  }
  
  .bg-white {
    background-color: #ffffff;
  }
  
  .rounded {
    border-radius: 0.25rem;
  }
  
  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .font-bold {
    font-weight: 700;
  }
  