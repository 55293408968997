/* src/components/ConfirmDialog.css */

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bg-gray-900 {
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  
  .bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  
  .z-50 {
    z-index: 50;
  }
  